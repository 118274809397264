@media (max-width:1309px) {
    .hero-section{
        padding: 1rem;
    }
    .grid-gen-con{
        gap: 0.8rem;
    }
      .researcher-con{
        left: -1rem;
      }
      .developer-con{
        left: 4rem;
      }
      .designer-con{
        left: 12rem;
      }
      .tech-enthusiast{
        left: 22rem;
      }

      /* community */
      .community-section{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 10rem;
      }
      .who-are-you-grid-element{
        width: 23rem;
      }
      .grid-element-imgs-1, .grid-element-imgs-2,.grid-element-imgs-3, .grid-element-imgs-4{
        width: 13rem;
      }
      /* PRICING */
      .pricing-1, .pricing-2, .pricing-3{
        width: 23rem;
      }
      .pricing-cons{
        gap: 1rem;
      }
      .hero-text-con >h1{
        font-size: 3rem;
        margin-bottom: 1.5rem;
      }
      .hero-text-con > p{
        font-size: 1rem;
      }
}
@media (max-width:1255px) {
    .tech-enthusiast{
        left: 21rem;
        transform: rotate(-28deg);
       
      
      }
}
@media (max-width:1249px) {
    .tech-enthusiast{
        left: 21rem;
        transform: rotate(-25deg);
       
      
      }
}
@media (max-width:1235px) {
    .grid-gen-con{
        grid-template-columns: auto 45% auto;
    }
    .pricing-cons{
        row-gap: 3rem;
    }
}
@media (max-width:1103px) {
    .grid-gen-con{
        grid-template-columns: auto 48% auto;
    }
    .center-grid-element{
        padding: 0;
    }
    
    .center-text-only-grid-con{
        margin-top: 0.5rem;
        padding: 0.3rem;
    }
}
@media (max-width:1083px) {
    .right-grid-element, .left-grid-element{
        align-self: last baseline;
    }
    .text-grid-con{
        padding-left: 0;
    }
}
@media (max-width:995px) {
    .travel-con1{
        overflow-y: scroll;
        padding: 0.5rem;
    }
}
@media (max-width:933px) {
    .network-image-con img,.techies-image-con img{
        width: 2rem;
        margin-left: -0.5rem;
      }
      
}
@media (max-width:887px) {
    .grid-con{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .who-are-you-grid-element{
        width: 100%;
    }
    .grid-element-imgs-1, .grid-element-imgs-2,.grid-element-imgs-3, .grid-element-imgs-4{
        width: 100%;
    }

}
@media (max-width:873px) {
    .mobile-icon{
        display: block;
    }
    .nav-con >ul{
        display: none;
    }
    .nav-con >button{
        display: none;
        
    }
    .grid-gen-con{
        display: none;
    }
    .mobile-hero-section-con{
        display: block;
    }
    .community-section{
        margin-top: 29rem;
    }
    .community-section-btn-con > button{
        display: block;
      }

    
}
@media (max-width:853px) {
    .slider1-elements, .slider-2-elements{
        width: 20rem;
        height: 15rem;
       
        
      }
      .next-btn{
        transform: translate(24rem,50%);
       
      }
      .arrow-bar-people{
        justify-content: space-between;
      }
    
}
@media (max-width:803px) {
    .slider1-elements, .slider-2-elements{
        width: 15rem;
        height: 15rem;
       
        
      }
      .price{
        font-size: 1.5rem;
      }
      .next-btn{
        transform: translate(19rem,50%);
       
      }
}
@media (max-width:769px) {
    .element-3{
        left: 10rem;

        
      
      }
}
@media (max-width:721px) {
    .arrow-bar-people, .arrow-bar-footer, .pricing-section-text-con{
        width: 30rem;
    }
}
@media (max-width:709px) {
    .community-section{
        margin-top: 34rem;
    }
    .arrow-bar-people, .arrow-bar-footer, .pricing-section-text-con{
        width: 30rem;
    }
}
@media (max-width:683px) {
    .arrow-bar-people, .arrow-bar-footer, .pricing-section-text-con{
        width: 30rem;
    }
    
    .pic-con img, .fascinating-pic-con img{
        width: 2rem;
        margin-left: -12px;
    }
    .fascinating-bar-people{
        width: 35rem;
    }
    .fascinating-gen-con{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    footer{
        padding-left: 0.5rem;
        padding-right:0.5rem ;
    }
}
@media (max-width:651px) {
    .element-3{
        left: 8rem;
      }
      .element-4{
        left: 1rem;
      }
}
@media (max-width:649px) {
    .mobile-center-text-only-grid-con{
        height: 15rem;
    }
}
@media (max-width:621px) {
    .pricing-section-text-con, .fascinating-section-text-con{
        width: 30rem;
    }
    .grid-gen-con{
        display: none;
    }
    .slider1-grid-con, .slider-2-grid-con{
        gap: 1rem;
      }
      .slider1-elements, .slider-2-elements{
        width: 13rem;
        height: 17rem;
      }
      .slider1-elements > h2, .slider2-elements > h2{
        text-align: center;
      }
      .next-btn{
        transform: translate(17rem,50%);
       
      }
}
@media (max-width:619px) {
    .text-center-grid{
        height: 13rem;
    }
}
@media (max-width:597px) {
    .arrow-bar-people, .arrow-bar-footer, .fascinating-bar-people{
        width: 32rem;
        gap: 0.8rem;
    }
    
    .pic-con img, .fascinating-pic-con img{
        width: 1.5rem;
        margin-left: -12px;
    }
    .pricing-section{
        padding: 0.5rem;
    }
}
@media (max-width:589px) {
    .slider1-elements, .slider-2-elements{
        width: 20rem;
        height: 15rem;
       
        
      }
      .slider1-grid-con, .slider-2-grid-con{
        grid-template-columns: 1fr;
        
      }
      .slider1-elements:last-child, .slider-2-elements:last-child{
        grid-column: auto; 
        
      }
      .next-btn{
        transform: translate(13rem,50%);
       
      }
}

@media (max-width:573px) {
    .element-3{
        left: 6rem;
      }
      .element-4{
        left: 0rem;
      }
}

@media (max-width:525px) {
    .arrow-bar-people, .arrow-bar-footer, .fascinating-bar-people{
        width: 30rem;
        gap: 0.8rem;
        padding: 0.2rem;
    }
    .footer-con{
        display: grid;
        grid-template-columns: 1fr;
    }
    .day-1{
        flex-direction: column;
        align-items: center;
    }
    .day >p, .day-1 > p{
       text-align: center;
        
      }
}

@media (max-width:509px) {
    .element-3{
        left: 5rem;
      }
      .element-4{
        left: -0.2rem;
      }
}
@media (max-width:499px) {
    .grid-con{
        display: grid;
        grid-template-columns: 1fr;
    }
      
      
    .arrow-bar-people, .arrow-bar-footer, .fascinating-bar-people{
        width: 27rem;
        gap: 0.8rem;
        padding: 0.2rem;
    }
    .pricing-section-text-con, .fascinating-section-text-con{
        width: 26rem;
    }
}
@media (max-width:485px) {
    .element-1, .element-2, .element-3, .element-4{
        width: 8rem;
        padding: 0.2rem;
       
       
    }
   
}
@media (max-width:477px) {
    .mobile-center-text-only-grid-con{
        height: 17rem;
    }
    .techies-image-con{
        display: flex;
        flex-wrap: nowrap;
    }
    .element-3{
        overflow: hidden;
    }
}
@media (max-width:461px) {
    .travel-con2{
        height: auto;
    }
    .community-section{
        margin-top: 35rem;
    }
    .techies-image-con img, .network-image-con img{
        width: 1.8rem;
        margin-left: -1rem;
    }
    .text-center-grid{
        height: auto;
    }
    .element-3{
        transform: rotate(40deg);
      }

      .element-2{
        left: 4rem;
      }
      .element-4{
        width: 9rem;
        
      }
      .element-1, .element-2, .element-3, .element-4{
        width: 12rem;
        padding: 0.5rem 0;
        
        /* bottom: -1rem; */
        transform: translate(0);
    }
    .element-1{
        top: -2rem;
    }
    
    .element-2{
        left: 0;
       
    }
    .element-3{
        left: 0;
    }
      
}
@media (max-width:457px) {
    .grid-con{
        display: grid;
        grid-template-columns: 1fr;
    }
    .footer-con-last{
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }
    .footer-logo-con{
        margin-top: 2rem;
    }
    .slider1-elements, .slider-2-elements{
        width: 18rem;
      }
      .next-btn{
        transform: translate(11.5rem,50%);
       
      }
      .slider-1-con, .slider-2-con{
        padding: 0.5rem;
      }
    
}
@media (max-width:459px) {
    .element-1, .element-2, .element-3, .element-4{
        width: 12rem;
        padding: 0.5rem 0;
        transform: translate(0);
    }
    .element-1{
        top: -2rem;
    }
    
    .element-2{
        top: 1rem;
       
    }
    .element-3{
        left: 0;
    }
    
    
}
@media (max-width:445px) {
    .arrow-bar-people, .arrow-bar-footer, .fascinating-bar-people{
        width: 23rem;
        gap: 0.8rem;
        padding: 0.2rem;
    }
    .pic-con img, .fascinating-pic-con img{
        width: 1.5rem;
        margin-left: -7px;
    }
}

@media (max-width:429px) {
    .pricing-section-text-con, .fascinating-section-text-con, .would{
        width: 24rem;
    }
    .travel-section{
        padding: 0.5rem;
    }
    
}
@media (max-width:403px) {
    .slider1-elements, .slider-2-elements{
        width: 15rem;
      }
      .next-btn{
        transform: translate(9.5rem,19%);
        padding: 0.3rem;
        width: 1.5rem;
       
      }
      .prev-btn{
        padding: 0.3rem;
        width: 1.5rem;
      } 
}
@media (max-width:407px) {
    .community-section{
        margin-top: 42rem;
    }
    .network-image-con img, .techies-image-con img{
        width: 1.5rem;
        margin-left: -0.5rem;
}
.element-1{
    top: -4rem;
}
.element-2{
    left: 0rem;
    top: 2rem;
}
.element-3{
    transform: rotate(25deg);
    top: 1rem;
    left: 0rem;
  }
  .element-4{
    top: 7rem;
  }
  .mobile-hero-grids{
    gap: 1rem;
}
.element-1, .element-2, .element-3, .element-4{
    width: 11rem;
    padding: 0.5rem;
}

.techies-con{
    padding: 0.1rem;
}

}
@media (max-width:397px) {
    .arrow-bar-people, .arrow-bar-footer, .fascinating-bar-people{
        width: 20rem;
        gap: 0.8rem;
        padding: 0.2rem;
    }
    .pricing-section-text-con, .fascinating-section-text-con, .would{
        width: 22rem;
    }
    .mobile-center-text-only-grid-con > p{
        margin-top: 0rem;
    }
}
@media (max-width:373px) {
    .element-1, .element-2, .element-3, .element-4{
        width: 9.5rem;
    }
   
    .mobile-center-text-only-grid-con > p{
        margin-top: 0rem;
    }
}
@media (max-width:360px) {
    .network-center-grid-con{
        display: flex;
        white-space: nowrap;
    }
}
@media (max-width:357px) {
    .pricing-section-text-con, .fascinating-section-text-con, .would{
        width: 20rem;
    }
    .network-image-con{
        display: flex;
        flex-wrap: nowrap;
    }
    .mobile-center-text-only-grid-con{
        height: 20rem;
    }
    .element-1, .element-2, .element-3, .element-4{
        width: 9rem;
    }
}
@media (max-width:347px) {
    .community-section{
        margin-top: 45rem;
    }
    
    .techies-image-con img, .network-image-con img{
        width: 1.2rem;
        
        
    }
    .mobile-center-text-only-grid-con{
        height: 20rem;
    }
}