.faq-section{
    margin-top: 6rem;
  }
  .faq-section >h2{
    text-align: center;
  }
  .faq-main-con{
    width: 35rem;
    margin: 0 auto;
  }
  .question-btn-con{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #D9D9D9;
    margin: 1rem 0 0 0;
    padding: 0.5rem;
    transition: 0.5s;
    cursor: pointer;
  }
  .question-btn-con >button{
    padding: 0.3rem;
    border-radius: 100%;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }
  .answer-con{
    background-color: whitesmoke;
    height: 3rem;
    padding: 0.5rem;
    transition: 0.5s;
    height: auto;
  }
  .faq-answer{
    padding-bottom: 1rem;
  }
  
  .extra-faq-answer{
    padding-bottom: 1rem;
  }
.faq-btn{
    background-color:purple;
    width: 10rem;
    border-radius: 20px;
    padding: 0.5rem 0.1rem;
    border: none;
}  
.faq-btn a{
    color: white;
}
@media (max-width:605px) {
    .faq-main-con{
        width: 30rem;
        
      }
}
@media (max-width:513px) {
    .faq-main-con{
        width: 27rem;
        
      }
}
@media (max-width:461px) {
    .faq-main-con{
        width: 23rem;
        
      }
}

@media (max-width:405px) {
    .faq-main-con{
        width: 20rem;
        
      }
}

@media (max-width:351px) {
    .faq-main-con{
        width: 18rem;
        
      }
}